/* global gtag_report_conversion */

import React, { useState } from 'react';
import emailjs from '@emailjs/browser';

function AdditionalInfo() {
  const [email, setEmail] = useState('');
  const [confirmationMessage, setConfirmationMessage] = useState('');

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    try {
      // Send email notification using EmailJS
      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID_FREETRIAL,
        {
          email: email,
          request_type: 'Free Trial',
        },
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      );

      setEmail('');
      setConfirmationMessage('Email submitted successfully!');
      
      // Call gtag_report_conversion after successful submission
      if (typeof gtag_report_conversion === 'function') {
        gtag_report_conversion();
      }
    } catch (error) {
      setConfirmationMessage('Failed to submit email. Please try again.');
    }
  };

  return (
    <div className="additional-info">
      <div className="info-container left">
        <h3>Want better search?</h3>
        <p>We've built tools to help companies manually search for better federal opportunities. You can search opportunities with your own queries, set filters and get emailed when new opportunities are released that match your criteria.</p>
        <form onSubmit={handleEmailSubmit}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
          />
          <button type="submit">Sign up for a free trial</button>
        </form>
        {confirmationMessage && <p className="confirmation-message">{confirmationMessage}</p>}
      </div>
      <div className="info-container right">
        <h3>Want help with the rest of the government contracting process?</h3>
        <p>We're building other tools to help with the federal contracting process. From automatically keeping up to date with compliance requirements to getting contracts set aside for small businesses like yours we can help.</p>
        <a href="https://calendly.com/crimzo-founders/quick-intro-clone" target="_blank" rel="noopener noreferrer">
          <button>Schedule a call with our founders</button>
        </a>
      </div>
    </div>
  );
}

export default AdditionalInfo;