import React from 'react';
import './styling/App.css';
import Header from './components/Header';
import CompanyList from './components/CompanyList';

function App() {
  return (
    <div className="App">
      <Header />
      <div className="main-content">
        <CompanyList />
      </div>
    </div>
  );
}

export default App;