import React, { useState, useEffect } from 'react';

function Header() {
  const [text, setText] = useState('');
  const fullTexts = ['opportunities', 'contracts', 'set asides', 'sources sought notices'];
  const [textIndex, setTextIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    const typingInterval = setInterval(() => {
      if (isPaused) return;

      const currentFullText = fullTexts[textIndex];

      if (!isDeleting && text === currentFullText) {
        setIsPaused(true);
        setTimeout(() => {
          setIsPaused(false);
          setIsDeleting(true);
        }, 1500);
        return;
      }

      if (isDeleting && text === '') {
        setIsDeleting(false);
        setIsPaused(true);
        setTimeout(() => {
          setIsPaused(false);
          setTextIndex((prevIndex) => (prevIndex + 1) % fullTexts.length);
        }, 500);
        return;
      }

      setText((prevText) => {
        if (isDeleting) {
          return prevText.slice(0, -1);
        } else {
          return currentFullText.slice(0, prevText.length + 1);
        }
      });
    }, 100);

    return () => clearInterval(typingInterval);
  }, [text, textIndex, isDeleting, isPaused]);

  return (
    <header className="header">
      <div className="header-content">
        <img src="/us-flag.png" alt="US Flag" className="us-flag" />
        <h1>Crimzo</h1>
      </div>
      <h2>Search for Federal {text}</h2>
    </header>
  );
}

export default Header;