/**
 * Capitalizes the first letter of each word in a string, 
 * except for letters following an apostrophe.
 * 
 * @param {string} str - The input string to be capitalized.
 * @return {string} The capitalized string.
 */
export const capitalizeString = (str) => {
  return str.toLowerCase().replace(/\b(\w)|\s('\w)/g, (match, p1, p2, offset) => {
    if (p2) {
      // If it's an apostrophe followed by a letter, keep the letter lowercase
      return match.toUpperCase().slice(0, -1) + match.slice(-1).toLowerCase();
    }
    // Otherwise, capitalize the first letter of each word
    return match.toUpperCase();
  });
};

