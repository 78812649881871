/* global gtag_report_conversion */

import React, { useState, useEffect, useRef } from 'react';
import emailjs from '@emailjs/browser';

function AutoDraftModal({ isOpen, onClose, company, opportunity }) {
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      // Send email notification using EmailJS with company and opportunity data
      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID_BID,
        {
          email: email,
          request_type: 'Auto Draft',
          company_name: company?.name_of_firm || 'N/A',
          company_id: company?.id || 'N/A',
          opportunity_title: opportunity?.title || 'N/A',
          opportunity_id: opportunity?.id || 'N/A',
          opportunity_type: opportunity?.type || 'N/A',
          opportunity_set_aside: opportunity?.type_of_set_aside_description || 'N/A',
          opportunity_response_deadline: opportunity?.response_deadline || 'N/A',
          opportunity_link: opportunity?.ui_link || 'N/A',
        },
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
      );

      setIsSubmitted(true);
      
      // Call gtag_report_conversion after successful submission
      if (typeof gtag_report_conversion === 'function') {
        gtag_report_conversion();
      }

    } catch (err) {
      setError('Failed to submit request. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setEmail('');
    setIsSubmitted(false);
    setError(null);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content" ref={modalRef}>
        {!isSubmitted ? (
          <>
            <h2>Automatically Draft Response</h2>
            <p>
              Crimzo can automatically draft a response to this opportunity for you. We'll use public information about your company from the Small Business Administration's database along with your public website to prepare a first draft, and send it to you within 24hrs at no charge.
            </p>
            <form onSubmit={handleSubmit}>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email here"
                required
              />
              <button type="submit" disabled={isLoading}>
                {isLoading ? 'Submitting...' : 'Request Draft'}
              </button>
            </form>
            {error && <p className="error-message">{error}</p>}
          </>
        ) : (
          <>
            <h2>Thank You!</h2>
            <p>
              Your request has been submitted successfully. We'll send the draft response to {email} within 24 hours.
            </p>
            <p>
              If you'd like to provide any additional information about your company that might be useful for us to include in the response, please email us directly at:
            </p>
            <p className="email-highlight">founders@crimzo.com</p>
          </>
        )}
        <button className="close-button" onClick={handleClose}>Close</button>
      </div>
    </div>
  );
}

export default AutoDraftModal;
