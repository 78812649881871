import React, { useState, useCallback, useEffect } from 'react';
import { capitalizeString } from '../utils/stringUtils';
import debounce from 'lodash/debounce';

function SearchBar({ companies = [], onSearchSubmit, onCompanySelect }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(-1);

  const debouncedSearch = useCallback(
    debounce((term) => {
      onSearchSubmit(term);
    }, 300),
    [onSearchSubmit]
  );

  useEffect(() => {
    if (searchTerm.length > 0 && companies.length > 0) {
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  }, [searchTerm, companies]);

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      setFocusedIndex(prev => (prev < companies.length - 1 ? prev + 1 : prev));
    } else if (e.key === 'ArrowUp') {
      setFocusedIndex(prev => (prev > 0 ? prev - 1 : prev));
    } else if (e.key === 'Enter' && focusedIndex >= 0) {
      handleCompanySelection(companies[focusedIndex]);
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };

  const handleInputFocus = () => {
    if (companies.length > 0 && searchTerm.length > 0) {
      setShowDropdown(true);
    }
  };

  const handleCompanySelection = (company) => {
    setSearchTerm(capitalizeString(company.name_of_firm));
    setShowDropdown(false);
    onCompanySelect(company);
  };

  return (
    <div className="search-container">
      <input
        type="text"
        placeholder="Search for a company..."
        value={searchTerm}
        onChange={handleSearchChange}
        onFocus={handleInputFocus}
        onKeyDown={handleKeyDown}
        className="search-input"
      />
      {showDropdown && (
        <ul className={`autocomplete-dropdown ${showDropdown ? 'show' : ''}`}>
          {companies.map((company, index) => (
            <li
              key={company.id}
              onClick={() => handleCompanySelection(company)}
              className={index === focusedIndex ? 'focused' : ''}
            >
              <strong>{capitalizeString(company.name_of_firm)}</strong>
              <span>{capitalizeString(company.address_city_state_zip)}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default SearchBar;