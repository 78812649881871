const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5001';

// Function to search for companies based on a search term
export const searchCompanies = async (searchTerm) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/company_search?term=${encodeURIComponent(searchTerm)}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

// Function to get opportunities for a specific company based on its ID
export const getOpportunities = async (companyId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/opportunity_search?company_id=${companyId}`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};