import React, { useState, useCallback, useMemo } from 'react';
import { capitalizeString } from '../utils/stringUtils';
import SearchBar from './SearchBar';
import CompanyOpportunities from './CompanyOpportunities';
import { searchCompanies } from '../api';
import AdditionalInfo from './AdditionalInfo';

function CompanyList() {
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [opportunitiesLoaded, setOpportunitiesLoaded] = useState(false);

  const handleSearchSubmit = useCallback(async (searchTerm) => {
    setIsLoading(true);
    try {
      const results = await searchCompanies(searchTerm);
      setCompanies(results);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleCompanySelect = useCallback((company) => {
    setSelectedCompany(company);
    setOpportunitiesLoaded(false);
    setCompanies([]); // Reset the companies list
  }, []);

  const capitalizedCompanies = useMemo(() => {
    return companies.map(company => ({
      ...company,
      name_of_firm: capitalizeString(company.name_of_firm),
      address_city_state_zip: capitalizeString(company.address_city_state_zip)
    }));
  }, [companies]);

  return (
    <div>
      <SearchBar
        companies={capitalizedCompanies}
        onSearchSubmit={handleSearchSubmit}
        onCompanySelect={handleCompanySelect}
      />
      {isLoading && <p>Loading companies...</p>}
      {selectedCompany && (
        <CompanyOpportunities 
          company={selectedCompany} 
          onOpportunitiesLoaded={() => setOpportunitiesLoaded(true)}
        />
      )}
      {opportunitiesLoaded && <AdditionalInfo />}
    </div>
  );
}

export default CompanyList;
