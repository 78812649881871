import React, { useState, useEffect } from 'react';
import { capitalizeString } from '../utils/stringUtils';
import { getOpportunities } from '../api';
import AutoDraftModal from './AutoDraftModal';

function CompanyOpportunities({ company, onOpportunitiesLoaded }) {
  const [opportunities, setOpportunities] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOpportunity, setSelectedOpportunity] = useState(null);

  useEffect(() => {
    const fetchOpportunities = async () => {
      if (!company) return;
      setIsLoading(true);
      setError(null);
      try {
        const results = await getOpportunities(company.id);
        setOpportunities(results);
        if (onOpportunitiesLoaded) {
          onOpportunitiesLoaded();
        }
      } catch (err) {
        setError('Failed to fetch opportunities. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchOpportunities();
  }, [company, onOpportunitiesLoaded]);

  const handleOpenModal = (opportunity) => {
    setSelectedOpportunity(opportunity);
    setIsModalOpen(true);
  };

  if (!company) {
    return null;
  }

  return (
    <div className="search-results">
      <h2>Opportunities for {capitalizeString(company.name_of_firm)}</h2>
      {isLoading && <p>Loading opportunities...</p>}
      {error && <p className="error">{error}</p>}
      {opportunities.length === 0 && !isLoading && !error && (
        <p>No opportunities found for this company.</p>
      )}
      {opportunities.map((opp) => (
        <div key={opp.id} className="result-item">
          <div className="result-content">
            <h3>{opp.title || 'Untitled Opportunity'}</h3>
            <p><strong>Type:</strong> {opp.type || 'N/A'}</p>
            <p><strong>Set-aside:</strong> {opp.type_of_set_aside_description || 'N/A'}</p>
            <p><strong>Response Deadline:</strong> {opp.response_deadline ? new Date(opp.response_deadline).toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' }).replace(/(\d+)/, (match) => `${match}${['th', 'st', 'nd', 'rd'][match > 3 && match < 21 ? 0 : match % 10 < 4 ? match % 10 : 0] || 'th'}`) : 'N/A'}</p>
            <p><strong>Place of Performance:</strong> {opp.place_of_performance?.city?.name || 'N/A'}</p>
            <p><strong><a href={opp.ui_link} target="_blank" rel="noopener noreferrer">Link to opportunity</a></strong></p>
            <div className="cta-container">
              <button onClick={() => handleOpenModal(opp)} className="cta-button">Automatically Draft Response</button>
            </div>
          </div>
        </div>
      ))}
      <AutoDraftModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} company={company} opportunity={selectedOpportunity} />
    </div>
  );
}

export default CompanyOpportunities;
